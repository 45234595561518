import { Component, React } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/footer/Footer.js';
import Navbar from './components/navbar/Navbar.js';
import Contact from './pages/contact/Contact.js';
import Events from './pages/events/Events.js';
import Home from './pages/home/Home.js';
import NotFound from './pages/NotFound/NotFound.js';

class App extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     isLoading: true,
  //   };
  // }

  // componentDidMount() {
  //   setTimeout(() => {
  //     this.setState({ isLoading: false });
  //   }, 200);
  // }

  render() {
    // const { isLoading } = this.state;
    // if (isLoading) {
    //         return (
    //             <div className="loader-container">
    //                 <Dna
    //                     visible={true}
    //                     height="120"
    //                     width="120"
    //                     ariaLabel="dna-loading"
    //                     wrapperStyle={{}}
    //                     wrapperClass="dna-wrapper"
    //                 />
    //             </div>
    //         );
    //     }
    
    return (
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Events />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    );
  }
}

export default App;
