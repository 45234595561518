import React from 'react';
import { Link } from 'react-router-dom';
import './event-card.css';

const EventCard = ({ event }) => {
    
    return (
        <div className="event-card">
            <div className="event-card-image-container">
                <Link to="/contact">
                    <img src={event.image} alt={event.name} className="event-card-image" />
                </Link>
            </div>
            <div className="event-card-info">
                {/* Event date */}

                <a href="/contact">
                    <h1 className="event-card-name">{event.name}</h1>
                    {/* <div className="event-card-date">
                        <h1 className="event-card-date">Date: {event.date}</h1>
                    </div> */}
                    <h2 className="event-card-text">{event.description}</h2>
                </a>
            </div>
        </div>
    );
}

export default EventCard;