import AOS from 'aos';
import 'aos/dist/aos.css';
import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import ServiceCard from '../../components/serviceCard/ServiceCard.js';
import './home.css';

const Home = () => {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const onScroll = () => {
            setScroll(window.scrollY);
        }
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);

    }, [scroll, setScroll]);

    const navbar = document.querySelector(".navbar");
    const navbarLinks = document.querySelectorAll(".navbar-link");
    if (navbar) {
        if (window.scrollY + 80 < window.innerHeight) {
            navbar.style.backgroundColor = "transparent";
        } else {
            navbar.style.backgroundColor = "#FFF1E6";
            navbarLinks.forEach(link => {
                link.style.color = "#000";
            });
        }
    }

    const taglineWords = ["mind", "body", "soul"];
    const [taglineWordIndex, setTaglineWordIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTaglineWordIndex((prevIndex) => (prevIndex + 1) % taglineWords.length);
        }, 2400);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div className="home-container">
            <section className="home-landing-section">
                <div className="landing-background-img-container">
                    <img src="/images/landing-background.jpg" alt="Landing" className="landing-background-img" />
                </div>
                <div className="landing-background-img-overlay">
                </div>
                <div className="landing-content">

                    <div className="landing-text-container">
                        <h1 className="landing-header" data-aos="fade-in" data-aos-delay="250" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-once="true">
                            C<span className="a-style">a</span>LM QUEST
                        </h1>

                        <div className="landing-text" data-aos="fade-in" data-aos-delay="1350" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-once="true">
                            where peace is more than a choice - a valued decision to reconnect with your &nbsp;
                            <span className={`${taglineWordIndex === 1 ? 'tag-word-rotation show' : 'tag-word-rotation'}`}>{taglineWords[1]}</span>
                            <span className={`${taglineWordIndex === 0 ? 'tag-word-rotation show' : 'tag-word-rotation'}`}>{taglineWords[0]}</span>
                            <span className={`${taglineWordIndex === 2 ? 'tag-word-rotation show' : 'tag-word-rotation'}`}>{taglineWords[2]}</span>
                        </div>

                        <Link to="/courses" className="landing-link" style={{ cursor: "pointer" }}>
                            <button className="landing-page-btn" data-aos="fade-in" data-aos-delay="1150" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-once="true">
                                View Courses
                            </button>
                        </Link>
                    </div>
                    <div className="landing-img-container">
                        <img src="/images/home-landing-1.jpg" alt="Landing" className="landing-img" />
                    </div>
                </div>
            </section>
            <section className="home-about-section" id="home-about-section">
                <div className="home-about-container">
                    <div>
                        <h1 className="about-header" data-aos="fade-in" data-aos-delay="600" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-once="true">
                            Embark on the quest for calmness
                        </h1>
                        <h2 className="about-text" data-aos="fade-in" data-aos-delay="300" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-once="true">
                            You don't learn to give a perfect speech in one go, or learn to ride a bike without falling, or deal with a painful situation without feeling overwhelmed. Right now, it may seem impossible to understand or master the issues that bought you here, but just like learning a bike or giving a perfect speech, it only gets better with time!
                        </h2>
                        <h2 className="about-text" data-aos="fade-in" data-aos-delay="300" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-once="true">
                            We know that reaching out for help can be challenging at times, even frightening; but it is the first step towards a more fulfilling life. So do not hesitate to get in touch with us!
                        </h2>
                    </div>
                    <a href="/contact" className="about-link">
                        <button className="about-btn">Get Started</button>
                    </a>
                </div>
            </section>
            <section className="home-services-section">
                <div className="home-services-container">
                    <h1 className="service-title">Our services</h1>
                    <h2 className="service-text">What type of therapy are you looking for?</h2>
                    <div className="service-card-container">
                        <ServiceCard title="Individual" image="/images/individual.png" topics={["Depression Counselling", "Anxiety Counselling", "OCD Counselling", "Anger Management", "Child Counselling", "Behavioral Issues", "Stress Management",]} />
                        <ServiceCard title="Couple" image="/images/couple.png" topics={["Communication & trust issues", "Sexual difficulties", "Addiction", "Lack of emotional intimacy", "Relationship satisfaction", "Anger issues in couples",]} />
                        <ServiceCard title="Family" image="/images/family.png" topics={["Parent-child conflict", "Grief", "Alcohol or drug abuse", "Distress", "Eating Disorders", "Family conflicts",]} />
                        <ServiceCard title="Career" image="/images/career.png" topics={["Appropriate career options & opportunities", "Detailed scope and guidance", "Issues in concentration", "Low academic performance", "Lack of interest in studies",]} />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;