import { React, useEffect, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import EventCard from './EventCard/EventCard';
import EventCarousel from './EventCarousel';
import './events.css';

const events = [
    
    {
        name: "Assertive boundaries",
        image: "/images/assertive-boundaries.jpg",
        // date: "26th November, 2023",
        description: "Discover the art of setting assertive boundaries essential for fostering healthy and respectful relationships, empowering individuals to prioritize self-care and mutual respect."
    },
    {
        name: "Stress Management",
        image: "/images/stress-management.jpg",
        // date: "2th December, 2023",
        description: "Learn to manage stress and anxiety through mindfulness, meditation and establishing healthy boundaries. Take charge of your well-being by incorporating stress management tools. "
    },
    {
        name: "Positive Psychology",
        image: "/images/positive-psychology.jpg",
        // date: "12th December, 2023",
        description: "Discover the science of happiness and learn to cultivate a positive mindset through positive psychology. Register yourself to know more about how you can use positive psychology in contributing to your mental well-being."
    },
    {
        name: "Cognitive Behavioral Therapy (CBT)",
        image: "/images/cbt-therapy.jpg",
        // date: "20th December, 2023",
        description: "Explore effective CBT techniques tailored to alleviate symptoms of depression, providing practical tools for mental wellness. Learn how your thoughts, behaviour, emotions, body sensations are linked to each other."
    },
    {
        name: "Relaxation Techniques",
        image: "/images/relaxation-techniques.jpg",
        // date: "22th December, 2023",
        description: "Discover effective relaxation techniques to nurture your mental well-being. From deep breathing exercises and progressive muscle relaxation to mindfulness meditation, learn how incorporating these techniques into your daily routine can alleviate stress and contribute to better mental well-being. Prioritize self-care and embark on a journey towards a more relaxed and resilient mindset."
    },
    {
        name: "Cognitive Drill Therapy (CDT)",
        image: "/images/CDT.jpeg",
        // date: "22th December, 2023",
        description: "CDT is an innovative approach to mental health that combines cognitive behavioral therapy (CBT) principles with targeted drill exercises. This powerful therapeutic technique aims to enhance cognitive processes, promote emotional regulation, and foster positive behavioral changes. Register to learn this technique that can be used with clients or even implement on yourself!"
    },
    {
        name: "Mindfulness-Based Cognitive Therapy (MBCT)",
        image: "/images/mbct.jpeg",
        // date: "22th December, 2023",
        description: "Learn how the fusion of mindfulness practices and cognitive therapy techniques empowers individuals to manage stress, anxiety, and depression. Discover the science behind MBCT and its proven effectiveness in cultivating greater self-awareness and resilience. Embrace a holistic approach to mental health that promotes lasting positive change. Start your journey towards a more mindful and balanced life with MBCT."
    },
    {
        name: "Art Therapy",
        image: "/images/art-therapy.jpeg",
        // date: "22th December, 2023",
        description: "Learn how to use art therapy in managing your emotions. Learn therapeutic techniques to help individuals navigate and process their emotions, fostering mental health through artistic exploration. This course blends theory and hands-on practice to empower participants in using art as a transformative tool for personal and psychological growth."
    },
    {
        name: "Color Therapy",
        image: "/images/color-therapy.jpeg",
        // date: "22th December, 2023",
        description: "Discover the impact of color on mental well-being in our Color Therapy course. Delve into the psychology of colors and their influence on emotions. Explore therapeutic applications, learning how to use specific hues to promote relaxation, reduce stress, and enhance mood. This course combines theory with practical exercises, providing a unique approach to mental health through the vibrant lens of color therapy. Engage in a colorful journey towards emotional balance and healing."
    },

];

const Events = () => {
    
    const [scroll, setScroll] = useState(0);
    
    useEffect(() => {
        const onScroll = () => {
            setScroll(window.scrollY);
        }
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);

    }, [scroll, setScroll]);

    const navbar = document.querySelector(".navbar");
    const navbarLinks = document.querySelectorAll(".navbar-link");
    if (navbar) {
        if (window.innerWidth > 768 && window.scrollY + 300 < window.innerHeight) {
            navbar.style.backgroundColor = "transparent";
        } else {
            navbar.style.backgroundColor = "#FFF1E6";
            navbarLinks.forEach(link => {
                link.style.color = "#000";
            });
        }
    }

        return (
            <div className="events-container">
                <EventCarousel />
                <section className="all-events">
                    <div className="event-heading">All Courses and Workshops</div>
                    <div className="event-cards-container">
                        {events.map((event, index) => (
                            <EventCard key={index} event={event} />
                        ))}
                    </div>
                </section>
            </div>
        )
    }

export default Events;
