import React from 'react';
import './event-slide.css';

const EventSlide = ({ imageName, altText, eventName, eventDescription }) => {
    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }
    return (
        <div className="slick-slide">
            <div className="image-container">
                <img src={imageName} alt={altText} className="slider-img" />
                <div className="overlay"></div>
            </div>
            <div className="event-info">
                    <h1 className="event-name">{eventName}</h1>
                    <h2 className="event-description">{truncate(eventDescription, 150)}</h2>
            </div>
        </div>
    );
};

export default EventSlide;