import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect } from 'react';
import './service-card.css';

const ServiceCard = ({ title, image, topics }) => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    return (
        <div className="card" data-aos="fade-in" data-aos-offset="130" data-aos-delay="250" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-once="true">
            <div className="card-side card-side-back">
                <div className="card-details">
                    <ul>
                        {topics.map((topic, index) => (
                            <li key={index}>{topic}</li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="card-side card-side-front">
                <div className="card-theme">
                    <p className="card-subject">{title}</p>
                    <img src={image} alt={title} className="service-card-image" />
                </div>
            </div>
        </div>
    );
}

export default ServiceCard;