import React from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
    return (
        <div className="navbar">
            <div className="navbar-logo">
                <Link to="/" className="logo-link">
                    <img src="/logo.png" alt="Calm Quest" className="logo-link-img" />
                </Link>
            </div>
            <div className="pixel">
                
            </div>
            <div className="navbar-links">
                <Link to="/" className="navbar-link">Home</Link>
                <Link to="/courses" className="navbar-link">Courses</Link>
                <Link to="/contact" className="navbar-link">Contact</Link>
            </div>
        </div>
    );
};

export default Navbar;
