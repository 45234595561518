import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Component, React } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import EventSlide from './EventSlide/EventSlide';
import './events.css';

const events = [

    {
        name: "Cognitive Drill Therapy (CDT)",
        image: "/images/CDT.jpeg",
        date: "26th November, 2023",
        description: "CDT is an innovative approach to mental health that combines cognitive behavioral therapy (CBT) principles with targeted drill exercises."
    },
    {
        name: "Mindfulness-Based Cognitive Therapy",
        image: "/images/mbct.jpeg",
        date: "26th November, 2023",
        description: "Learn how the fusion of mindfulness practices and cognitive therapy techniques empowers individuals to manage stress, anxiety, and depression."
    },
    {
        name: "Art Therapy",
        image: "/images/art-therapy.jpeg",
        date: "26th November, 2023",
        description: "Learn how to use art therapy in managing your emotions."
    },
    {
        name: "Color Therapy",
        image: "/images/color-therapy.jpeg",
        date: "26th November, 2023",
        description: "Discover the impact of color on mental well-being in our Color Therapy course."
    },   
    {
        name: "Assertive boundaries",
        image: "/images/assertive-boundaries.jpg",
        date: "26th November, 2023",
        description: "Discover the art of setting assertive boundaries essential for fostering healthy and respectful relationships, empowering individuals to prioritize self-care and mutual respect."
    },
    {
        name: "Stress Management",
        image: "/images/stress-management.jpg",
        date: "2th December, 2023",
        description: "Learn to manage stress and anxiety through mindfulness, meditation and relaxation techniques."
    },
    {
        name: "Positive Psychology",
        image: "/images/positive-psychology.jpg",
        date: "12th December, 2023",
        description: "Discover the science of happiness and learn to cultivate a positive mindset through positive psychology."
    },
    {
        name: "Cognitive Behavioral Therapy (CBT)",
        image: "/images/cbt-therapy.jpg",
        date: "20th December, 2023",
        description: "Explore effective Cognitive Behavioral Therapy techniques tailored to alleviate symptoms of depression, providing practical tools for mental wellness."
    },
    {
        name: "Relaxation Techniques",
        image: "/images/relaxation-techniques.jpg",
        date: "22th December, 2023",
        description: "Learn to manage stress and anxiety through mindfulness, meditation and relaxation techniques."
    },
];

class EventCarousel extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 900,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: false,
        };

    return (
        <section className="events-section">
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        {events.map((event, index) => (
                            <EventSlide
                                key={index}
                                imageName={event.image}
                                altText={event.name}
                                eventName={event.name}
                                eventDescription={event.description}
                            />
                        ))}
                    </Slider>

                    <div className="slide-control">
                        <button className="previous" onClick={this.previous}>
                            <div>
                                <ArrowBackIosIcon />
                            </div>
                        </button>
                        <button className="next" onClick={this.next}>
                            <div>
                                <ArrowForwardIosIcon />
                            </div>
                        </button>
                    </div>
                </section>
    );
    }
}

export default EventCarousel;