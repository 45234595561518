import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="row1">
        <div className="footer-about">
          <h2 className="footer-about-title">About</h2>
          <p className="footer-about-text">
          At Calm Quest, we believe in creating an environment where your mental wellness is not just a destination, but a fulfilling journey. Step into a supportive community where we host events, offer online therapy sessions, and provide expert counselling guidance tailored to your unique mental health journey.
          </p>
          <p className="footer-about-text">
          Have a question or want to schedule an appointment? Get in touch with us, we are here to hear you!
          </p>
        </div>

        <div className="footer-quick-links">
          <h2 className="footer-quick-links-title">Quick Links</h2>
          <ul className="footer-quick-links-list">
            <li className="footer-quick-links-list-item">
              <Link to="/" className="footer-quick-links-list-item-link">Home</Link>
            </li>
            <li className="footer-quick-links-list-item">
              <Link to="/courses" className="footer-quick-links-list-item-link">Courses</Link>
            </li>
            <li className="footer-quick-links-list-item">
              <Link to="/contact" className="footer-quick-links-list-item-link">Contact</Link>
            </li>
          </ul>
          <div className="footer-socials">
          <a href="mailto:contact.calmquest@gmail.com" className="footer-socials-link">
            <EmailIcon className="footer-socials-link-icon" />
          </a>
          <a href="" className="footer-socials-link">
            <LinkedInIcon className="footer-socials-link-icon" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61554893198831&mibextid=LQQJ4d" className="footer-socials-link">
            <FacebookIcon className="footer-socials-link-icon" />
          </a>
          <a href="https://www.instagram.com/calmquest.in?igsh=MXVxdWk1MHVocDBqdQ==" className="footer-socials-link">
            <InstagramIcon className="footer-socials-link-icon" />
          </a>
        </div>
        </div>

      </div>
      <div className="row2">
        <div className="footer-copyright">
          <p className="footer-copyright-p">
            Copyright &copy; 2023 All Rights Reserved by Calm Quest
          </p>
        </div>
        {/* <div className="footer-socials">
          <a href="mailto:contact.calmquest@gmail.com" className="footer-socials-link">
            <EmailIcon className="footer-socials-link-icon" />
          </a>
          <a href="" className="footer-socials-link">
            <LinkedInIcon className="footer-socials-link-icon" />
          </a>
          <a href="" className="footer-socials-link">
            <FacebookIcon className="footer-socials-link-icon" />
          </a>
          <a href="" className="footer-socials-link">
            <InstagramIcon className="footer-socials-link-icon" />
          </a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
